export enum MessageType {
  PageView,
  ButtonClick,

  ShowMessageCampaign,
  CloseMessageCampaign,
  ClickMessageCampaign,

  Logout,

  AddEmployees,
  RemoveEmployee,
}

export interface Message<T extends MessageType> {
  type: T;

  payload?: any;
}
